import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { VueShowdownPlugin } from 'vue-showdown';
import axios from 'axios';
import VueAxios from 'vue-axios';

const app = createApp();
app.use(VueAxios, axios);

app.use(VueShowdownPlugin, {
    flavor: 'github',
    options: {
        emoji: true,
    },
});

createApp(App).use(store).use(router).mount('#app');
