export const routesAll = [{
        path: '/',
        name: 'Home',
        component: () => import('../views/home/home.vue')
    },
    {
        path: '/destinos',
        name: 'Destinos',
        component: () => import('../views/destinos/destinos.vue')
    },
    {
        path: '/contactar',
        name: 'Contactar',
        component: () => import('../views/contactar/contactar.vue')
    },
    {
        path: '/servicios',
        name: 'Servicios',
        component: () => import('../views/servicios/servicios.vue')
    },
    {
        path: '/noticias/:id',
        name: 'Noticias',
        component: () => import('../views/noticias/noticias.vue')
    },
    {
        path: '/entidad/:id',
        name: 'Entidad',
        component: () => import('../views/entidad/entidad.vue')
    },
    {
        path: '/participacion-ciudadana',
        name: 'Participacion',
        component: () => import('../views/participacion/participacion.vue')
    },
    {
        path: '/ver-transparencia-acceso/:id',
        name: 'verTransparenciaAcceso',
        component: () => import('../views/verTransparenciaAcceso/verTransparenciaAcceso.vue')
    },
    {
        path: '/internas/:id',
        name: 'Access',
        component: () => import('../views/access/Access.vue')
    },
    {
        path: '/ver-transparencia-acceso/privacy-data-processing',
        name: 'PoliticasPrivacidad',
        component: () => import('../views/politicas-privacidad/politicas-privacidad.vue')
    },
    {
        path: '/ver-transparencia-acceso/cookie-policies',
        name: 'CookiePolicies',
        component: () => import('../views/CookiePolicies/CookiePolicies.vue')
    },
    {
        path: '/ver-transparencia-acceso/terms-and-conditions',
        name: 'TermsAndConditions',
        component: () => import('../views/TermsAndConditions/TermsAndConditions.vue')
    }
    /*{
        path: '/buscar',
        name: 'Buscar',
        component: () => import('../views/Buscar.vue')
    },*/
];