import { createRouter, createWebHistory } from 'vue-router';
import { routesAll } from './routers'

const routes = routesAll;

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 };
    }
});

export default router;