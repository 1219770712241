<template>
    <div class="d-block w-100 bg-blue px-4 py-1">
        <a href="https://www.gov.co/" target="_blank">
            <img src="@/assets/img/index/govcom.svg" alt="" width="150">
        </a>
    </div>
    <div class="container-fluid navegacion tema-principal">
        <div class="row justify-content-lg-center">
            <nav class="navbar navbar-expand-lg p-0 col-12 col-lg-auto">
                <ul class="navbar-nav text-uppercase w-100 text-center">
                    <li class="nav-item active">
                        <a class="nav-link font-weight-bold" @click="inicio">
                            Viajeros
                        </a>
                    </li>
                    <li class="nav-item d-none d-lg-block">
                        <router-link class="nav-link font-weight-bold" to="/noticias/inicio">Noticias</router-link>
                    </li>
                    <li class="nav-item d-none d-lg-block">
                        <a target="_black" href="http://ninos.terminaldesantamarta.com/" class="nav-link font-weight-bold">Mi terminalito</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
    <!-- navegacion segun la pagina -->
    <div class="container-fluid navegacion-seccion sticky-top bg-blanco">
        <div class="row">
            <div class="container-lg">
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex justify-content-lg-center justify-content-between align-items-center py-2">
                            <div class="col-lg-auto">
                                <router-link to="/">
                                    <div v-show="textoTema === 'Claro'">
                                        <img src="@/assets/img/index/logo-ts-white.png" alt="" height="60">
                                    </div>
                                    <div v-show="textoTema === 'Oscuro'">
                                        <img src="@/assets/img/index/logo-ts-orange.png" alt="" height="60">
                                    </div>
                                </router-link>
                            </div>
                            <div class="col-lg col-auto">
                                <div class="d-flex justify-content-end">
                                    <div class="col-lg-auto">
                                        <nav :class="['navbar navbar-expand-md p-0 text-uppercase font-weight-bold justify-content-sm-center', { 'activo' : menuToogleResponsive }]">
                                            <div class="collapse navbar-collapse" id="navbarNav">
                                                <ul class="navbar-nav">
                                                    <li class="nav-item">{{ compfontSize }}</li>
                                                    <li class="nav-item">
                                                        <a class="nav-link cur-pointer" @click="inicio">
                                                            Mi terminal
                                                        </a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link class="nav-link" to="/entidad/tranparenciaAcceso" @click="scrollToTop">TRANSPARENCIA Y ACCESO </router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link class="nav-link" to="/entidad/AtencionCiudadano" @click="scrollToTop">ATENCIÓN Y SERVICIO</router-link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <router-link class="nav-link" to="/participacion-ciudadana">PARTICIPA</router-link>
                                                    </li>
                                                    <li class="nav-item no-visible-es">
                                                        <router-link class="nav-link" to="/noticias/inicio" @click="scrollToTop">Noticias</router-link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </nav>
                                    </div>
                                    <!-- <div class="col-lg-auto novisible-en-xl">
                                        <form class="form d-flex m-0 form-nav-search">
                                            <input class="form-control col" type="search" placeholder="Buscar" aria-label="Search">
                                            <button class="btn col-auto" type="submit">
                                                <i class="icon-search"></i>
                                            </button>
                                        </form>
                                    </div> -->
                                </div>
                            </div>
                            <div class="col-md-auto col novisible-en-xl">
                                <a target="_blank" href="https://www.santamarta.gov.co/" class="mr-3">
                                    <img :src="require(`@/assets/img/index/logo_alcaldia-smr-2020-naranja.png`)" alt="" height="50">
                                </a>
                                <a target="_blank" href="https://www.magdalena.gov.co/">
                                    <img :src="require(`@/assets/img/index/logo-fc-naranja.png`)" alt="" height="50">
                                </a>
                                <a target="_blank" href="http://www.magdalena.gov.co/" class="ml-3">
                                    <img :src="require(`@/assets/img/index/logo-escudo-sm.png`)" alt="" height="50">
                                </a>
                            </div>
                            <div class="col-auto d-md-none">
                                <button class="btn" @click="menuToogleResponsive = !menuToogleResponsive">
                                    <i class="icon-menu"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="position-absolute d-flex flex-column buttons-menu">
            <a target="_blank" href="https://www.facebook.com/Terminal-Santa-Marta-831588453872160/" class=" bg-facebook">
                <i class="icon-facebook2"></i>
            </a>
            <a target="_blank" href="https://twitter.com/TerminalSanMart" class=" bg-twitter">
                <i class="icon-twitter"></i>
            </a>
            <a target="_blank" href="#" class="bg-instagram ">
                <i class="icon-instagram"></i>
            </a>
        </div>
        <div class="panelaccesibilidad">
            <button type="button" class="btn" v-on:click="queso">
                <i class="icon-contrast"></i>
            </button>
            <button type="button" class="btn" v-on:click="fontSizeMas">
                A+
            </button>
            <button type="button" class="btn" v-on:click="fontSizeMenos">
                A-
            </button>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Navegacion',
    data() {
        return {
            textoTema: 'Oscuro',
            fontSize: 0,
            menuToogleResponsive: false,
            accecibilidad: false,
        }
    },
    mounted: function() {
        this.confLocalStorage();
    },
    methods: {
        queso() {
            let body = document.querySelector('#app');
            if (this.textoTema === 'Oscuro') {
                body.classList.add('black');
                this.textoTema = 'Claro';
            } else {
                body.classList.remove('black');
                this.textoTema = 'Oscuro';
            }
            localStorage.setItem('textoTema', this.textoTema);
        },
        inicio() {
            this.$router.push('/');
            setTimeout(() => {
                location.reload();
            }, 50)
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        confLocalStorage() {
            let item = localStorage.getItem('textoTema');
            if (item != undefined) {
                let body = document.querySelector('#app');
                if (item === 'Claro') {
                    body.classList.add('black');
                    this.textoTema = 'Claro';
                } else {
                    body.classList.remove('black');
                    this.textoTema = 'Oscuro';
                }
            }
        },
        fontSizeMas() {
            if (this.fontSize < 2) {
                this.fontSize++;
                console.log("this.fontSize", this.fontSize);
            }
        },
        fontSizeMenos() {
            if (this.fontSize > 0) {
                this.fontSize--;
                console.log("this.fontSize", this.fontSize);
            }
        }
    },
    computed: {
        compfontSize() {
            let body = document.querySelector('#app');
            let size;
            switch (this.fontSize) {
                case 0:
                    size = 1;
                    break;
                case 1:
                    size = 1.15;
                    break;
                case 2:
                    size = 1.25;
                    break;
            }
            body.setAttribute('style', `font-size: ${size}rem`)
        }
    }
}
</script>
<style>
.bg-blue {
    background-color: #0066cd;
}
.navegacion-seccion .navbar a.nav-link {
    font-size: .95rem !important;
}
.panelaccesibilidad {
    position: fixed;
    right: 0;
    top: 50%;
    display: flex;
    flex-direction: column;
    z-index: 10;
    background-color: #ff6d00;
    padding: 8px;
    border-radius: 5px 0 0 5px;
}

.panelaccesibilidad .btn {
    background-color: #fff;
    border: 0;
    font-weight: bold;
}

.panelaccesibilidad .btn+.btn {
    margin-top: .5rem;
}

.btn-letra {
    display: flex;
    align-items: center;
    font-weight: bold;
}

.btn-letra .peq {
    font-size: 1.2rem;
}

.letra {
    display: block;
    padding: 5px;
    font-size: 1.5rem;
    margin-right: 10px;
    line-height: 1;
    color: inherit;
    border-radius: 8px;
}

.accecibilidad {
    position: fixed;
    left: -170px;
    border-radius: 12px;
    bottom: 10%;
    width: 170px;
    background-color: #fff;
    padding: 10px;
    box-shadow: 0 0 15px 1px rgba(0, 0, 0, .2);
    transition: all ease-in-out 200ms;
}

.accecibilidad.activo {
    left: 0;
}

.accecibilidad .btn-accecible {
    position: absolute;
    left: 100%;
    top: 0;
    background: #fff;
    box-shadow: 0 0 15px 1px rgba(0, 0, 0, .2);
    margin-left: 1rem;
}

.navegacion-seccion .form-nav-search input {
    width: 70px;
    transition: width 200ms ease-in-out;
}

.navegacion-seccion .form-nav-search input:focus {
    width: 240px;
}

li.no-visible-es {
    display: none !important;
}

@media (max-width: 992px) {
    .novisible-en-xl {
        display: none;
    }

    li.no-visible-es {
        display: block !important;
    }
}

@media (max-width: 767px) {
    .navegacion-seccion .novisible-en {
        display: none
    }

    .navegacion-seccion .navbar {
        position: fixed;
        left: -250px;
        top: 0;
        background: #fff;
        height: 100%;
        display: block;
        z-index: 1000;
        width: 250px;
        transition: all 200ms ease-in-out;
    }

    .navegacion-seccion .navbar.activo {
        left: 0;
    }

    .navegacion-seccion #navbarNav {
        display: block;
        margin-top: 1rem;
    }
}
</style>