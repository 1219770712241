<template>
    <Navegacion></Navegacion>
    <router-view />
    <Footer />
</template>
<script>
import Footer from '@/components/global/Footer.vue'
import Navegacion from '@/components/global/Navegacion.vue'

export default {
    name: 'Home',
    components: {
        Footer,
        Navegacion
    }
}
</script>