import { createStore } from 'vuex';

export default createStore({
    state: {
        BASE_URL: 'https://noticias.terminaldesantamarta.com',
        BASE_URL_MAIN: 'https://terminaldesantamarta.com',
        urlServer: 'http://localhost:8081/',
        cantPasajeros: true,
        pasajeros: [],
        redesSociales: [
            {
                nombre: 'facebook',
                url: 'http://www.facebook.com/sharer.php?u=',
                icon: 'icon-facebook'
            },
            {
                nombre: 'twitter',
                url: 'https://www.twitter.com',
                icon: 'icon-twitter'
            },
            {
                nombre: 'instagram',
                url: 'https://www.instagram.com',
                icon: 'icon-instagram'
            },
        ]
    },
    mutations: {
        agregarPasajeros(state, p) {
            state.pasajeros = p;
        },
        MutcantPasajeros(state, p) {
            if (p > 0) {
                state.cantPasajeros = false;
            } else {
                state.cantPasajeros = true;
            }
        }
    },
    actions: {
        actionAgregarPasajeros({ commit }, p) {
            commit('agregarPasajeros', p);
        },
        actionCantPasajeros({ commit }, p) {
            commit('MutcantPasajeros', p);
        }
    },
    modules: {
    }
});
