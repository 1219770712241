<template>
    <footer>
        <div class="container mb-3">
            <div class="row">
                <div class="col-12 carrucel">
                    <div class="carousel-wrap">
                        <div class="owl-carousel">
                            <div class="item" v-for="(item, index) of owlcarrusel" v-bind:key="index">
                                <a target="_blank" :href="item.url">
                                    <img :class="item.zise" :src="require(`@/assets/img/carrucel/${item.img}`)" alt="">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-block tema-principal footer-container text-light">
            <section class="container-fluid">
                <div class="row container-footer">
                    <div class="col-auto mb-4 mb-md-0 mr-4 footer-atajos text-center">
                        <a target="_blank" href="https://www.gov.co/">
                            <img src="@/assets/img/index/govcom.svg" alt="" width="150" class="mb-3"><br>
                        </a>
                        <a target="_blank" href="https://www.colombia.co/">
                            <img src="@/assets/img/index/cocolombia.svg" alt="" width="50">
                        </a>
                    </div>
                    <div class="col-lg col-12 mb-4 mb-md-0 footer-atajos">
                        <h5>Terminal de Santa Marta</h5>
                        <hr>
                        <ul>
                            <li class="d-flex align-items-center">
                                <div class="col-auto">
                                    <i class="icon-location h3"></i>
                                </div>
                                <div class="col">
                                    Calle 41 No. 31-17 Carretera Troncal del Caribe Variante Gaira-Mamatoco Santa Marta-Colombia
                                </div>
                            </li>
                            <li class="d-flex align-items-center">
                                <div class="col-auto">
                                    <i class="icon-mail2 h3"></i>
                                </div>
                                <div class="col">
                                    <h5>Contacto</h5>
                                    <a href="mailto:centraltsm@terminaldesantamarta.com">centraltsm@terminaldesantamarta.com</a>
                                    <br>
                                    <a href="mailto:centraltsm@hotmail.com">centraltsm@hotmail.com</a>
                                    <br>
                                    <a href="mailto:notificacionjudicial@terminaldesantamarta.com">notificacionjudicial@terminaldesantamarta.com</a>
                                </div>
                            </li>
                            <li class="d-flex align-items-center">
                                <div class="col-auto">
                                    <i class="icon-phone h3"></i>
                                </div>
                                <div class="col">
                                    <h5>Teléfono</h5>
                                    <a>3160104538</a>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg col-12 mb-4 mb-md-0 footer-atajos horario-list">
                        <h5>Horario de atención:</h5>
                        <hr>
                        <div class="d-flex">
                            <div class="col-auto">
                                <i class="icon-clock h3"></i>
                            </div>
                            <div class="col">
                                <div class="row">
                                    <div class="col-12 border-bottom pb-3">
                                        <h6>Atención Administrativa (de oficina)</h6>
                                        <small>De lunes a viernes: 8 am - 12m | 2pm -6pm</small>
                                    </div>
                                    <div class="col-12 border-bottom py-3">
                                        <h6>De Atención al usuario</h6>
                                        <small>8am - 6pm</small>
                                    </div>
                                    <div class="col-12 pt-3">
                                        <h6>Atención de venta de tiquetes:</h6>
                                        <small>4 am -11 pm</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg col-12 mb-4 mb-md-0 footer-atajos">
                        <h5>Entidad</h5>
                        <hr>
                        <ul>
                            <li>
                                <router-link to="/entidad/tranparenciaAcceso" class="dropdown-item" @click="scrollToTop">Transparencia y acceso</router-link>
                            </li>
                            <li>
                                <router-link to="/entidad/informacion-corporativa" class="dropdown-item" @click="scrollToTop">Información corporativa</router-link>
                            </li>
                            <li>
                                <router-link to="/entidad/#" class="dropdown-item" @click="scrollToTop">Mapa del sitio</router-link>
                            </li>
                            <li>
                                <router-link to="/ver-transparencia-acceso/avisoPrivacidad" class="dropdown-item" @click="scrollToTop">Políticas y privacidad</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 mt-5">
                    <div class="row align-items-center justify-content-center text-center">
                        <div class="col-md-auto mb-5 mr-4 mb-md-0 col-12">
                            <div class="row align-items-center">
                                <a :target="item.redireccion" :href="item.url" class="col" v-for="(item, index) of enlacesFooter" v-bind:key="index">
                                    <img :width="item.size" :src="require(`@/assets/img/index/${item.img}`)" alt="terminal de transporte santa marta">
                                </a>
                            </div>
                        </div>
                        <div class="col-md-auto mb-5 mb-md-0 col-12">
                            <router-link to="/contactar" class="btn bg-white btn-lg color-principal" @click="scrollToTop">
                                <i class="icon-phone mr-3"></i> Contactanos
                            </router-link>
                        </div>
                        <div class="col-md-auto mb-5 ml-4 mb-md-0 col-12">
                            <ul class="list-social d-inline-flex">
                                <li>
                                    <a target="_blank" href="https://www.facebook.com/Terminal-Santa-Marta-831588453872160/" title="">
                                        <i class="icon-facebook"></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://twitter.com/TerminalSanMart" title="">
                                        <i class="icon-twitter"></i>
                                    </a>
                                </li>
                                <li>
                                    <a target="_blank" href="https://www.instagram.com/terminalsantamarta/" title="">
                                        <i class="icon-instagram"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </footer>
</template>
<script>
import { listCarrucel } from '@/assets/data/owlcarrusel.js';

export default {
    name: 'Navegacion',
    data: function() {
        return {
            owlcarrusel: listCarrucel,
            enlacesFooter: [{
                    url: 'https://www.supertransporte.gov.co/',
                    img: 'logo-si.png',
                    size: 110,
                    redireccion: '_blank'
                }, {
                    url: 'http://www.magdalena.gov.co/',
                    img: 'logo-gm.png',
                    size: 120,
                    redireccion: '_blank'
                },
                {
                    url: 'https://www.santamarta.gov.co/',
                    img: 'santa-marta-logo.png',
                    size: 130,
                    redireccion: '_blank'
                }
            ]
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        }
    },
    mounted: function() {
        $(document).ready(function() {
            $(".owl-carousel").owlCarousel({
                nav: true,
                loop: true,
                margin: 20,
                responsive: {
                    0: {
                        items: 1
                    },
                    768: {
                        items: 3
                    },
                    1200: {
                        items: 5
                    }
                }
            });
        });
    }
}
</script>
<style lang="css">
footer {
    margin-top: 4rem;
}

footer .carrucel {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.footer-container {
    padding: 3rem 0;
}

.container-footer ul li+li {
    margin-top: .75rem;
}

.container-footer ul {
    padding: 0;
}

.container-footer a {
    background-color: transparent !important;
    color: #fff !important;
    padding: 0;
}

.container-footer a:hover {
    text-decoration: underline;
}

footer .carousel-wrap {
    text-align: center;
}

footer .owl-stage {
    display: flex;
    align-items: center;
}

footer .carousel-wrap img {
    width: 100px !important;
    display: inline-block !important;
    height: auto;
    filter: grayscale(90%);
    opacity: .5;
    transition: all 200ms ease-in-out;
}

footer .carousel-wrap a:hover img {
    filter: grayscale(0);
    opacity: 1;
}

footer .carousel-wrap img.xgra {
    width: 205px !important;
}

footer .carousel-wrap img.gra {
    width: 135px !important;
}

footer .carousel-wrap img.med {
    width: 100px !important;
}

footer .carousel-wrap img.peq {
    width: 80px !important;
}

footer .carousel-wrap img.min {
    width: 70px !important;
}

footer .owl-nav .owl-prev,
footer .owl-nav .owl-next {
    display: flex;
    width: 40px;
    height: 40px;
    background-color: #fff !important;
    z-index: 1;
    box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    position: absolute;
    top: calc(50% - 30px);
    outline: none !important;
}

footer .owl-nav .owl-prev:active,
footer .owl-nav .owl-next:active {
    background-color: #F2E8E8 !important;
}

footer .owl-prev span,
footer .owl-next span {
    font-size: 3rem;
    line-height: 0;
    position: absolute;
    top: calc(50% - 7px);
}

footer .owl-nav .owl-prev {
    left: -30px;
}

footer .owl-nav .owl-next {
    right: -30px;
}

footer .horario-list .border-bottom {
    border-color: rgba(255, 255, 255, 0.2) !important;
}
</style>