export const listCarrucel = [{
        url: 'https://id.presidencia.gov.co/deinteres/index.html',
        img: 'f-1.webp',
        zise: 'min',
    },
    {
        url: 'http://www.magdalena.gov.co/',
        img: 'f-2.png',
        zise: 'min',
    },
    {
        url: 'https://www.supertransporte.gov.co/',
        img: 'f-3.png',
        zise: 'min',
    },
    {
        url: 'https://www.policia.gov.co/',
        img: 'f-4.png',
        zise: 'peq',
    },
    {
        url: 'https://www.mintransporte.gov.co/',
        img: 'f-5.png',
        zise: 'xgra',
    },
    {
        url: 'https://www.mintic.gov.co/portal/inicio/Glosario/G/5306:Gobierno-en-Linea-GEL',
        img: 'f-6.png',
        zise: 'peq',
    },
    {
        url: 'https://www.santamarta.gov.co/',
        img: 'f-7.png',
        zise: 'peq',
    },
    {
        url: 'https://www.suin-juriscol.gov.co/',
        img: 'f-8.png',
        zise: 'xgra',
    },
]